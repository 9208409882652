import { useState } from "react";
import { validate } from "../../utils/utils";
import Button from "./Button";
import config from "../../config/global";

const NewsletterInput = () => {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const showMessage = (msg: string) => {
    setMessage(msg);
    // Optional: clear message after some time
    setTimeout(() => setMessage(""), 5000);
  };

  const subscribeToNewsletter = async () => {
    const resBody = {
      name: "Abonare Newsletter",
      email: email,
      message: "Acest utilizator s-a abonat in home page.",
    };
    try {
      const res = await fetch(`${config.API_URL}/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(resBody),
      });
      if (res.ok) {
        setEmail(""); // Clear form fields
        showMessage("V-ați abonat cu succes. Vă mulțumim!"); // Show success message
      } else {
        // If the server responded with an error, handle it here
        showMessage(
          "Eroare la trimiterea mesajului. Vă rugăm să încercați din nou.",
        );
      }
    } catch (error) {
      console.error(error);
      showMessage(
        "Eroare la trimiterea mesajului. Vă rugăm să verificați conexiunea la internet.",
      );
    }
  };
  const messageStyle = {
    opacity: message ? 1 : 0,
    transition: "opacity 0.3s ease", // This is optional for a fade effect
  };
  const emailIsValid = validate(email, { email: true });

  return (
    <div>
      <div className="w-full flex flex-col lg:flex-row mt-12 lg:mt-8 gap-4 justify-center items-center h-10">
        <input
          className="w-full max-w-sm px-4 py-1 rounded-lg h-full"
          type="text"
          placeholder="adresă email"
          value={email} // Control the input's value with the email state
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(ev.target.value);
          }}
        />

        <Button beige onClick={subscribeToNewsletter} disabled={!emailIsValid}>
          confirmă
        </Button>
      </div>
      <div
        className="text-fgHomepageDarkText text-center mt-4"
        style={messageStyle}
      >
        {message || "\u00A0"}
      </div>
    </div>
  );
};

export default NewsletterInput;
